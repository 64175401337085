<template>
  <v-app-bar app color="yellow lighten-2" light>
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img
          class="shrink mr-2"
          contain
          src="../assets/logo(1).png"
          transition="scale-transition"
          width="75"
        />
      </router-link>
      <router-link to="/">
        <v-toolbar-title class="ml-2 text-h6">Melissa Rooms</v-toolbar-title>
      </router-link>
    </div>

    <v-spacer></v-spacer>
    <div class="d-none d-md-block">
      <v-btn text class="text-button" to="/Rooms">Rooms & Rates</v-btn>
      <v-btn text class="mr-lg-10 text-button" to="/Information"
        >Information</v-btn
      >
    </div>
    <span class="mr-5 text-center d-none d-lg-block">
      <v-icon class="mr-2">mdi-phone</v-icon>+306987879475
      <v-divider></v-divider>
      <slot>
        <v-icon @click.prevent="mail" class="mr-2">mdi-email</v-icon
        >melissanxs@gmail.com
      </slot>
    </span>

    <div class="d-md-none">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i">
            <router-link :to="item.link">
              <v-list-item-title class="text-button">{{
                item.title
              }}</v-list-item-title>
            </router-link>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="my-2 text-button">
              +306987879475
              <v-divider class="my-1"></v-divider>

              <v-icon @click="mail" class="mr-2">mdi-email</v-icon>mail us!
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    mail() {
      window.location.href = "mailto:melissanxs@gmail.com";
    },
  },
  data: () => ({
    items: [
      { title: "Rooms & Rates", link: "Rooms" },
      { title: "Information", link: "Information" },
    ],
  }),
};
</script>
<style scoped>
a {
  text-decoration: none;
}

.v-application a {
  color: black;
}
</style>
