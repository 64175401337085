<template>
  <v-container>
    <v-row class="mt-10">
      <v-col cols="12" md="2" class="mt-lg-2">
        <v-btn
          class="yellow lighten-2 lg-ml-10 text-center text-button"
          x-large
          block
          href="https://goo.gl/maps/DoZp9juGp7bwyztR6"
        >
          <v-icon class="mr-2">mdi-google-maps</v-icon>Find us!
        </v-btn>
      </v-col>
      <v-col cols="12" md="8" class="mt-6 d-none d-md-block">
        <h1 class="font-weight-regular text-center">
          Welcome to Melissa Rooms, Naxos
        </h1>
      </v-col>
      <v-col cols="12" md="2">
        <Booking />
      </v-col>
    </v-row>
    <Carousel />
  </v-container>
</template>

<script>
import Carousel from "../components/Carousel";
import Booking from "../components/Booking";
export default {
  components: {
    Carousel,
    Booking,
  },
};
</script>
