<template>
  <v-carousel
    class="mt-10"
    height="700"
    contain
    hide-delimiters
    cycle
    dark
    interval="9000"
    progress
    next-icon="mdi-menu-right"
    prev-icon="mdi-menu-left"
    show-arrows
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="i in 8"
      :key="i"
      :src="require(`../assets/Homepage/${i}.jpg`)"
      reverse-transition="fade-transition"
      transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
