<template>
  <v-card class="mx-auto mt-lg-15" max-width="1800">
    <v-container>
      <subtitle><b> Contact us for special offers!</b> </subtitle>
      <v-row dense>
        <v-col
          v-for="card in cards"
          :key="card.title"
          :sm="card.sm"
          :md="card.md"
          :offset-md="card.offset"
        >
          <v-card :to="card.link">
            <v-img :src="card.src" class="white--text align-end" height="600px">
              <v-card-title v-text="card.title"></v-card-title>
            </v-img>

            <v-card-actions class="justify-center">
              <div class="my-2 subtitle-2">
                <v-chip class="ma-2" color="yellow lighten-2">
                  {{ card.price1 }}
                </v-chip>
              </div>
              <div class="my-4 subtitle-2">{{ card.months1 }}</div>
              <div class="my-4 subtitle-2">
                <v-chip class="ma-2" color="yellow lighten-1">
                  {{ card.price3 }}
                </v-chip>
              </div>
              <div class="my-4 subtitle-2">{{ card.months3 }}</div>
              <div class="my-4 subtitle-2">
                <v-chip class="ma-2" color="yellow lighten-1">
                  {{ card.price2 }}
                </v-chip>
              </div>

              <div class="my-4 subtitle-2">{{ card.months2 }}</div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    cards: [
      {
        title: "Floor rooms with balcony",
        src: require("../assets/floor.jpg"),

        md: 6,
        sm: 12,
        link: "/Floor",
        price1: "50€",
        months1: " rest of year, ",
        price2: "125€",
        months2: " in August",
        price3: "110€",
        months3: " in July",
      },
      {
        title: "Semi-basement Rooms with yard",
        src: require("../assets/yard.jpg"),

        md: 6,
        sm: 12,
        link: "/SemiTerrace",
        price1: "45€",
        months1: " rest of year, ",
        price2: "110€",
        months2: " in August",
        price3: "100€",
        months3: " in July",
      },
      {
        title: "Semi Basement Room",
        src: require("../assets/semi.jpg"),

        md: 6,
        sm: 12,
        link: "/SemiBasement",
        price1: "40€",
        months1: "rest of year, ",
        price2: "90€",
        months2: " in August",
        price3: "80",
        months3: " in July",
        offset: "3",
      },
    ],
  }),
};
</script>
