<template>
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-col cols="12">
          <v-btn
            class="yellow lighten-2 text-center text-button"
            block
            x-large
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-2">mdi-book-open-outline</v-icon>Book directly
          </v-btn>
        </v-col>
      </template>
      <v-card>
        <v-card-title>
          <v-toolbar color="deep-orange accent-2" dark flat>
            <v-card-title class="title font-weight-regular"
              >Booking Request</v-card-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="First Name*"
                    v-model="firstName"
                    :rules="inputRules"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Last Name*"
                    v-model="lastName"
                    :rules="inputRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    v-model="email"
                    :rules="inputRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Phone*"
                    v-model="phone"
                    :rules="inputRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="rooms"
                    label="Room Type"
                    :rules="inputRules"
                    v-model="roomType"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="inputRules"
                        v-model="checkInDate"
                        label="Check-in date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="blue lighten-2"
                      v-model="checkInDate"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs2 }">
                      <v-text-field
                        :rules="inputRules"
                        v-model="checkOutDate"
                        label="Check Out Date"
                        readonly
                        v-bind="attrs2"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="blue lighten-2"
                      v-model="checkOutDate"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <p>
            -We will contact you to confirm your booking shortly
            <br />-no prepayment required
          </p>
          <small
            >-check Information page for details regarding prices, check-in/out
            times, etc</small
          >
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="sendMail">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import emailjs from "emailjs-com";

export default {
  methods: {
    sendMail() {
      if (this.$refs.form.validate()) {
        var templateParams = {
          name: this.firstName,
          surname: this.lastName,
          room: this.roomType,
          checkin: this.checkInDate,
          checkout: this.checkOutDate,
          phone: this.phone,
          email: this.email,
        };
        emailjs
          .send(
            "yahoo",
            "template_HZRvVrfZ",
            templateParams,
            "user_4Z9rwqZeiANQUgR5hKUQX"
          ) //use your Service ID and Template ID
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
        this.dialog = false;
      }
    },
  },
  data: () => ({
    dialog: false,
    menu: false,
    menu2: false,
    checkInDate: "",
    checkOutDate: "",
    rooms: ["Floor Room", "Semi-basement with yard", "Semi-basement"],
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roomType: "",
    inputRules: [(v) => !!v || "This field is required"],
  }),
};
</script>
