import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Information from "../views/Information.vue";
import Rooms from "../views/Rooms.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Information",
    name: "Information",
    component: Information,
  },
  {
    path: "/Rooms",
    name: "Rooms",
    component: Rooms,
  },
  {
    path: "/Floor",
    name: "Floor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Floor.vue"),
  },
  {
    path: "/SemiTerrace",
    name: "SemiTerrace",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/SemiTerrace.vue"),
  },
  {
    path: "/SemiBasement",
    name: "SemiBasement",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/SemiBasement.vue"),
  },
  { path: "*", redirect: { name: "Home" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
