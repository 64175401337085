<template>
  <v-main>
    <div>
      <v-col>
        Located at Naxos town, Melissa rooms is a new project created by young
        people who strive to offer high quality services with a friendly
        attitude.
      </v-col>
    </div>
    <v-container fluid>
      <v-data-iterator
        :items="items"
        :items-per-page.sync="itemsPerPage"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar class="mb-2" color="green lighten-1" dark flat>
            <v-toolbar-title contain>
              Below you can find useful information regarding rooms,services
              offered and terms.
            </v-toolbar-title>
          </v-toolbar>
        </template>

        <template>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-card>
                <v-card-title class="subheading font-weight-bold"
                  >Naxos Melissa rooms include:</v-card-title
                >

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-bed</v-icon>
                    </v-list-item-content>
                    <v-list-item-content>2 single beds</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-fridge</v-icon>
                    </v-list-item-content>
                    <v-list-item-content>1 refrigerator</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-toilet</v-icon>
                    </v-list-item-content>
                    <v-list-item-content>private bathroom</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-air-conditioner</v-icon>
                    </v-list-item-content>
                    <v-list-item-content>Air Conditioning</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-television</v-icon>
                    </v-list-item-content>
                    <v-list-item-content>TV screen</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-hair-dryer</v-icon>
                    </v-list-item-content>
                    <v-list-item-content>Hair-dryer</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-safe</v-icon>
                    </v-list-item-content>
                    <v-list-item-content>Safe</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-card>
                <v-card-title class="subheading font-weight-bold"
                  >Services:</v-card-title
                >
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="align-end">
                    <v-icon>mdi-wifi</v-icon>
                  </v-list-item-content>
                  <v-list-item-content>Free Wifi</v-list-item-content>
                </v-list-item>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-spray-bottle</v-icon>
                    </v-list-item-content>
                    <v-list-item-content>Daily cleaning</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="align-end">
                      <v-icon>mdi-bed</v-icon>
                    </v-list-item-content>
                    <v-list-item-content
                      >Sheets change every second day</v-list-item-content
                    >
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-icon>mdi-account-clock</v-icon>
                    </v-list-item-content>
                    <v-list-item-content class="align-end"
                      >Owner/staff always available</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-list-item-content>
                    <v-list-item-content class="align-end">
                      Always available to talk about the island and recommend
                      places you should visit
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-card>
                <v-card-title class="subheading font-weight-bold"
                  >Additional information:</v-card-title
                >

                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>check-in hours:</v-list-item-content>
                    <v-list-item-content class="align-end font-weight-bold"
                      >13:00 - 24:00</v-list-item-content
                    >
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>check-out hours:</v-list-item-content>
                    <v-list-item-content class="align-end font-weight-bold"
                      >9:00-11:00</v-list-item-content
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content
                      >Close to Naxos town's beach "Saint
                      George"</v-list-item-content
                    >
                    <v-list-item-content class="align-end font-weight-bold"
                      >5 minutes</v-list-item-content
                    >
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      Always happy to listen to your recommendations and
                      proposals.
                      <br />Drop us an email,viber/whatsapp message or give us a
                      call!
                    </v-list-item-content>

                    <v-list-item-content>
                      <v-icon>mdi-email</v-icon>
                      <v-icon>mdi-whatsapp</v-icon>
                      <v-icon>mdi-phone</v-icon>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-toolbar class="mt-5" color="orange lighten-2" dark flat>
            <v-toolbar-title class="subheading"
              >Mail us @ melissanxs@gmail.com for
              questions/reservations</v-toolbar-title
            >
          </v-toolbar>
        </template>
      </v-data-iterator>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    itemsPerPage: 3,
    items: [{}],
  }),
};
</script>

<style></style>
