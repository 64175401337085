<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  name: "App",

  components: { Navbar },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.theme--light.v-application {
  background: rgb(255, 255, 236);
}
</style>

<style>
html,
body {
  font-family: "Playfair Display", serif;
}

#app {
  font-family: "Playfair Display", serif;
}
</style>
